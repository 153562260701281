import { useState, useEffect } from "react";
import React from "react";
import "../App.css";
import "./app.jsx";
import axios from "axios";
import GaleryModal from "./Galerymodal";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";

export function Admin() {
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        setIpAddress(ip);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération de l'adresse IP :",
          error
        );
      });
  }, [ipAddress]);
  if (ipAddress === process.env.REACT_APP_ALLOWED_IP) {
    return (
      <div
        className="admin-page text-white pt-8 bg-no-repeat text-center "
        style={{
          fontWeight: "100",
          backgroundColor: "black",
          backgroundSize: "50%",
        }}
      >
        <div>
          <AdminMenu></AdminMenu>
          <AdminForm></AdminForm>
          <AdminList></AdminList>
        </div>
      </div>
    );
  }
}

export function AdminMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleOpenMenu = (e) => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="mt-8 grid col-12 justify-content-center ">
      <div
        onClick={(e) => handleOpenMenu(e)}
        className="flex flex-column w-2rem md:hidden mt-5"
      >
        <button className="mb-1"></button>
        <button className="mb-1"></button>
        <button className="mb-1"></button>
      </div>
      <div>
        {isMenuOpen && (
          <div className="opened-menu flex flex-column md:hidden h-20rem justify-content-between pl-8 pr-8 pt-3 pb-3 mt-5 border-round-2xl ">
            <Link to="/Galery">Galerie</Link>

            <Link to="/Artiste">L'artiste</Link>

            <Link to="/Contact">Contact</Link>
          </div>
        )}
      </div>
      <div className=" hidden md:inline md:col-7 lg:col-5">
        <hr className=""></hr>
        <div className="flex justify-content-between">
          <Link to="/Galery">Galerie</Link>
          <Link to="/Artiste">L'artiste</Link>
          <Link to="/Contact">Contact</Link>
        </div>
      </div>
    </div>
  );
}

export function AdminForm() {
  const [array, setArray] = useState({
    name: "",
    order: "",
    description: "",
    dimension: "",
    image: null,
    image2: null,
    image3: null,
    image4: null,
    type: "",
    type2: "",
    serial: "",
    year: "",
    price: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArray({ ...array, [name]: value });
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    setArray({ ...array, [fieldName]: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (let key in array) {
      formData.append(key, array[key]);
    }

    axios
      .post(`${process.env.REACT_APP_POST_ARRAYS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setArray({
          name: "",
          order: "",
          description: "",
          dimension: "",
          image: null,
          image2: null,
          image3: null,
          image4: null,
          type: "",
          type2: "",
          serial: "",
          year: "",
          price: "",
        });
      });
  };

  return (
    <div>
      <p className="text-5xl pt-8">Interface Administrateur</p>
      <div>
        <div className="admin-form text-xl p-7 ">
          <h1>Ajouter un tableau</h1>
          <form
            method="POST"
            encType="multipart/form-data"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <label htmlFor="name">Nom (obligatoire): </label>
            <input
              type="text"
              id="name"
              name="name"
              value={array.name || ""}
              onChange={(e) => {
                handleChange(e);
              }}
              required
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="order">Ordre de rangement (obligatoire): </label>
            <input
              type="text"
              id="order"
              name="order"
              value={array.order || ""}
              onChange={(e) => {
                handleChange(e);
              }}
              required
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="description">Description (obligatoire): </label>
            <textarea
              id="description"
              name="description"
              value={array.description || ""}
              rows="10"
              onChange={(e) => {
                handleChange(e);
              }}
              required
            ></textarea>
            <br></br>
            <br></br>
            <label htmlFor="dimension">Dimension (obligatoire): </label>
            <input
              type="text"
              id="dimension"
              name="dimension"
              value={array.dimension || ""}
              onChange={(e) => {
                handleChange(e);
              }}
              required
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="image">Image (obligatoire): </label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={(e) => {
                handleFileChange(e, "image");
              }}
              accept="image/*"
              required
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="image2">Image avec fond (obligatoire): </label>
            <input
              type="file"
              id="image2"
              name="image2"
              onChange={(e) => {
                handleFileChange(e, "image2");
              }}
              accept="image/*"
              required
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="image3">Image 2 : </label>
            <input
              type="file"
              id="image3"
              name="image3"
              onChange={(e) => {
                handleFileChange(e, "image3");
              }}
              accept="image/*"
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="image4">Image 3 : </label>
            <input
              type="file"
              id="image4"
              name="image4"
              onChange={(e) => {
                handleFileChange(e, "image4");
              }}
              accept="image/*"
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="type">type (obligatoire): </label>
            <input
              type="text"
              id="type"
              name="type"
              value={array.type || ""}
              onChange={(e) => {
                handleChange(e);
              }}
              required
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="type2">type 2 : </label>
            <input
              type="text"
              id="type2"
              name="type2"
              value={array.type2 || ""}
              onChange={(e) => {
                handleChange(e);
              }}
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="serial">Série : </label>
            <input
              type="text"
              id="serial"
              name="serial"
              value={array.serial || ""}
              onChange={(e) => {
                handleChange(e);
              }}
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="year">Année de création : </label>
            <input
              type="text"
              id="year"
              name="year"
              value={array.year || ""}
              onChange={(e) => {
                handleChange(e);
              }}
            ></input>
            <br></br>
            <br></br>
            <label htmlFor="price">Prix : </label>
            <input
              type="text"
              id="price"
              name="price"
              step="0.01"
              value={array.price || ""}
              onChange={(e) => {
                handleChange(e);
              }}
            ></input>
            <br></br>
            <br></br>

            <input
              className="array-submit text-2xl p-2 mt-5"
              style={{ backgroundColor: "rgb(107, 182, 32)" }}
              type="submit"
              value="Ajouter le Tableau à la galerie"
            ></input>
          </form>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export function AdminList() {
  const [arrays, setArrays] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [mainPic, setMainPic] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_ARRAYS}`, {
        params: {
          $sort: {
            order: -1,
          },

          $select: [
            "id",
            "name",
            "order",
            "imagecompressed",
            "image2compressed",
          ],
        },
        mode: "cors",
      });
      const data = response.data;
      setArrays(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données : ", error);
    }
  };

  const openModal = (arrayId) => {
    fetchSelectedArray(arrayId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedArray([]);
    setMainPic("");
    fetchData();
    setModalOpen(false);
  };

  const fetchSelectedArray = async (arrayId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_ARRAYS}/${arrayId}`,
        {
          params: {
            $select: [
              "id",
              "name",
              "order",
              "description",
              "dimension",
              "year",
              "image",
              "image2",
              "image3",
              "image4",
              "type",
              "type2",
              "price",
              "serial",
            ],
          },
          mode: "cors",
        }
      );
      const arrayData = response.data;
      setSelectedArray(arrayData);
      setMainPic(arrayData.image);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données du tableau sélectionné : ",
        error
      );
    }
  };
  const handleClick = (array) => {
    fetchSelectedArray(array.id);
    openModal();
  };

  return (
    <div className="grid justify-content-center bg-white">
      <div className="grid col-12 justify-content-center">
        {arrays.map((array) => (
          <div
            className="col-6 md:col-3 py-8 h-20rem flex flex-column-reverse justify-content-center align-items-center mb-6 sm:mb-6 md:mb-2 lg:mb-2 xl:mb-8 "
            key={array.id}
            style={{ color: "rgb(6, 6, 6)" }}
          >
            <div
              className="fondu relative cursor-pointer col-8"
              value={array}
              onClick={() => handleClick(array)}
            >
              <img
                className="galerie-images-bas  border-round-xl absolute left-0 w-full"
                src={`data:image/webp;base64,${array.image2compressed}`}
                alt={array.name}
              ></img>
              <img
                className="galerie-images-haut  border-round-xl absolute left-0 w-full"
                src={`data:image/webp;base64,${array.imagecompressed}`}
                alt={array.name}
              ></img>
            </div>

            <div className="col-12">
              <p className="g-array-title">{array.name}</p>
              <p className="g-array-dimension">ordre : {array.order} ème</p>
              <hr></hr>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && selectedArray.id && (
        <GaleryModal isOpen={isModalOpen} onClose={closeModal}>
          <AdminArray
            array={selectedArray}
            closeModal={closeModal}
            fetchData={fetchData}
            mainPic={mainPic}
          ></AdminArray>
        </GaleryModal>
      )}
    </div>
  );
}

export function AdminArray({ array, closeModal, fetchData }) {
  // Set main pic state
  const [mainPic, setMainPic] = useState(array.image);
  const handleMainPic = (e) => {
    const newMainPic = e.target.getAttribute("value");
    setMainPic(newMainPic);
  };

  // State to create a modified array object
  const [modifiedArray, setModifiedArray] = useState(array);

  // States for edition mode
  const [editDescription, setEditDescription] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [editYear, setEditYear] = useState(false);
  const [editDimension, setEditDimension] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editType, setEditType] = useState(false);
  const [editType2, setEditType2] = useState(false);
  const [editPrice, setEditPrice] = useState(false);
  const [editSerial, setEditSerial] = useState(false);

  // handler to make fields on modif or not
  const toggleEditName = () => {
    setEditName(!editName);
  };
  const toggleEditOrder = () => {
    setEditOrder(!editOrder);
  };
  const toggleEditDescription = () => {
    setEditDescription(!editDescription);
  };
  const toggleEditYear = () => {
    setEditYear(!editYear);
  };
  const toggleEditDimension = () => {
    setEditDimension(!editDimension);
  };
  const toggleEditType = () => {
    setEditType(!editType);
  };
  const toggleEditType2 = () => {
    setEditType2(!editType2);
  };
  const toggleEditPrice = () => {
    setEditPrice(!editPrice);
  };
  const toggleEditSerial = () => {
    setEditSerial(!editSerial);
  };

  // Function to set up modifications
  const handleSave = (e) => {
    e.preventDefault();
    const updatedArray = {
      id: modifiedArray.id,
      name: modifiedArray.name,
      order: modifiedArray.order,
      year: modifiedArray.year,
      description: modifiedArray.description,
      dimension: modifiedArray.dimension,
      type: modifiedArray.type,
      type2: modifiedArray.type2,
      price: modifiedArray.price,
      serial: modifiedArray.serial,
    };

    axios.post(`${process.env.REACT_APP_MODIF_ARRAYS}`, updatedArray, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  // Function to delete arrays
  const handleDelete = (e) => {
    const deletedArray = {
      id: array.id,
    };
    axios.post(`${process.env.REACT_APP_DELETE_ARRAYS}`, deletedArray, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    closeModal();
  };
  return (
    <div className="array-page text-xl grid col-12 " style={{ color: "black" }}>
      {/* modification du nom */}
      <form
        method="POST"
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSave(e);
        }}
      >
        <div className="col-12">
          {editName ? (
            <input
              type="text"
              id="name"
              name="name"
              value={modifiedArray.name}
              onChange={(e) =>
                setModifiedArray({
                  ...modifiedArray,
                  name: e.target.value,
                })
              }
            ></input>
          ) : (
            <span className="text-5xl">{modifiedArray.name}</span>
          )}
          <button onClick={toggleEditName}>
            {editName ? (
              <button className="save-icon"></button>
            ) : (
              <button className="modifier"></button>
            )}
          </button>
        </div>
        <div className="col-12 mt-3 grid">
          <div className="card flex grid col-12 md:col-5 align-content-center">
            <Image
              className="array-main-pic"
              width="100%"
              src={`data:image/webp;base64,${mainPic}`}
              alt={array.name}
            />
            <hr/>
            <div className="select col-12 flex justify-content-between ">
              <img
                src={`data:image/webp;base64,${array.image}`}
                value={array.image}
                onClick={(e) => handleMainPic(e)}
                alt={array.name}
              ></img>

              <img
                src={`data:image/webp;base64,${array.image2}`}
                value={array.image2}
                onClick={(e) => handleMainPic(e)}
                alt={array.name}
              ></img>

              <img
                src={`data:image/webp;base64,${array.image3}`}
                value={array.image3}
                onClick={(e) => handleMainPic(e)}
                alt={array.name}
              ></img>

              <img
                src={`data:image/webp;base64,${array.image4}`}
                value={array.image4}
                onClick={(e) => handleMainPic(e)}
                alt={array.name}
              ></img>
            </div>
          </div>

          <div className="array-info grid flex flex-column col-12 md:col-7">
            {/* modification de l'ordre */}

            <div>
              <p>Ordre :</p>
              {editOrder ? (
                <input
                  type="text"
                  id="order"
                  name="order"
                  value={modifiedArray.order}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      order: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.order}</span>
              )}
              <button onClick={toggleEditOrder}>
                {editOrder ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
            {/* modification de l'année */}
            <div>
              <p>Année (visible):</p>
              {editYear ? (
                <input
                  type="text"
                  id="year"
                  name="year"
                  value={modifiedArray.year}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      year: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.year}</span>
              )}
              <button onClick={toggleEditYear}>
                {editYear ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>

            {/* modification de la description */}

            <div>
              <p>Description (visible):</p>
              {editDescription ? (
                <textarea
                  value={modifiedArray.description}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      description: e.target.value,
                    })
                  }
                ></textarea>
              ) : (
                <span>{modifiedArray.description}</span>
              )}
              <button onClick={toggleEditDescription}>
                {editDescription ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
            <br></br>
            <br></br>
            {/* modification de la dimension */}

            <div>
              <p>Dimensions (visible):</p>
              {editDimension ? (
                <input
                  type="text"
                  id="dimension"
                  name="dimension"
                  value={modifiedArray.dimension}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      dimension: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.dimension}</span>
              )}
              <button onClick={toggleEditDimension}>
                {editDimension ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
          </div>
          </div>
          
        <hr/>
          <div className="array-description justify-content-between flex w-full grid col-12">
            {/* modification du type  */}

            <div>
              <p>Type 1 :</p>
              {editType ? (
                <input
                  type="text"
                  id="type"
                  name="type"
                  value={modifiedArray.type}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      type: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.type}</span>
              )}
              <button onClick={toggleEditType}>
                {editType ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
            {/* modification du Type 2 */}

            <div>
              <p>Type 2:</p>
              {editType2 ? (
                <input
                  type="text"
                  id="type2"
                  name="type2"
                  value={modifiedArray.type2}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      type2: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.type2}</span>
              )}
              <button onClick={toggleEditType2}>
                {editType2 ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
            {/* modification du prix */}

            <div>
              <p>Prix :</p>
              {editPrice ? (
                <input
                  type="text"
                  id="price"
                  name="price"
                  value={modifiedArray.price}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      price: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.price}</span>
              )}
              <button onClick={toggleEditPrice}>
                {editPrice ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
            {/* modification de la Série */}

            <div>
              <p>Serie :</p>
              {editSerial ? (
                <input
                  type="text"
                  id="serial"
                  name="serial"
                  value={modifiedArray.serial}
                  onChange={(e) =>
                    setModifiedArray({
                      ...modifiedArray,
                      serial: e.target.value,
                    })
                  }
                ></input>
              ) : (
                <span>{modifiedArray.serial}</span>
              )}
              <button onClick={toggleEditSerial}>
                {editSerial ? (
                  <button className="save-icon"></button>
                ) : (
                  <button className="modifier"></button>
                )}
              </button>
            </div>
          </div>
        
        <button
          className="delete mt-5 p-3 text-white text-3xl border-round-3xl"
          onClick={(e) => {
            handleDelete();
          }}
          >
          Supprimer le tableau
        </button>
          
      </form>
    </div>
  );
}

export default Admin;
