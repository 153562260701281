import React from "react";
import "../App.css";
import "./app.jsx";
import { useState, useEffect, useCallback } from "react";
import GaleryModal from "./Galerymodal";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../static/logonoir.png";
import { Image } from 'primereact/image';
        

// Galery page component
export function Galery() {
  return (
    <div className="galery-page text-center absolute w-screen h-screen bg-no-repeat grid col-12 text-xl md:text-lg">
      <div>
        <GaleryHeader></GaleryHeader>
        <GalerieContain></GalerieContain>
      </div>
    </div>
  );
}

// Galery header component
export function GaleryHeader() {
  return (
    <header>
      <div className="galery-header h-full w-screen grid col-12 justify-content-center mb-9 " style={{backgroundColor: "rgba(255, 255, 255, 0.704)"}}>
        <div className="col-12">
          <img className="col-11 md:col-8 lg:col-6" src={logo} alt="logo"></img>
          <GaleryMenu></GaleryMenu>
        </div>
      </div>
    </header>
  );
}

// Galery menu component
export function GaleryMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenu = (e) => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="galery-menu grid col-12 justify-content-center ">
      <div
        onClick={(e) => handleOpenMenu(e)}
        className="flex flex-column w-2rem md:hidden mt-5"
      >
        <button className="mb-1"></button>
        <button className="mb-1"></button>
        <button className="mb-1"></button>
      </div>
      <div>
        {isMenuOpen && (
          <div className="opened-menu-w flex flex-column md:hidden h-20rem justify-content-between pl-8 pr-8 pt-3 pb-3 mt-5 border-round-2xl ">
            <Link to="/">Accueil</Link>
            <Link to="/Galery">Galerie</Link>
            <Link to="/Artiste">L'artiste</Link>
            <Link to="/Contact">Contact</Link>
          </div>
        )}
      </div>
      <div className=" hidden md:inline md:col-7 lg:col-5">
        <hr className=""></hr>
        <div className="flex justify-content-between">
          <Link to="/">Accueil</Link>
          <Link to="/Galery">Galerie</Link>
          <Link to="/Artiste">L'artiste</Link>
          <Link to="/Contact">Contact</Link>
        </div>
      </div>
    </div>
  );
}

// Galery body component
export function GalerieContain() {
  const [selectedArray, setSelectedArray] = useState([]);
  const [arrays, setArrays] = useState([]);
  const [mainPic, setMainPic] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  //Lazy download
  const fetchData = useCallback(
    async (pageNum) => {
      if (loading || !hasMore) return;

      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_ARRAYS}`,
          {
            params: {
              $sort: {
                order: -1,
              },
              $limit: 10,
              $skip: (pageNum - 1) * 10,
              $select: [
                "id",
                "name",
                "dimension",
                "imagecompressed",
                "image2compressed",
              ],
            },
            mode: "cors",
          }
        );
        const data = response.data;

        if (data.length === 0) {
          setHasMore(false);
        } else {
          setArrays((prevArrays) => [...prevArrays, ...data]);
          setPage(pageNum + 1);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données : ", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, hasMore]
  );
  // Arrays download
  useEffect(() => {
    fetchData(page);
  }, [fetchData, page]);

  // Opening modal for Array-details
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (arrayId) => {
    fetchSelectedArray(arrayId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedArray([]);
    setMainPic("");
    setModalOpen(false);
  };

  // Set selected array for details

  const fetchSelectedArray = async (arrayId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_ARRAYS}/${arrayId}`,
        {
          params: {
            $select: [
              "id",
              "name",
              "description",
              "dimension",
              "year",
              "image",
              "image2",
              "image3",
              "image4",
            ],
          },
          mode: "cors",
        }
      );
      const arrayData = response.data;
      setSelectedArray(arrayData);
      setMainPic(arrayData.image);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données du tableau sélectionné : ",
        error
      );
    }
  };

  // Select array function
  const handleClick = (array) => {
    fetchSelectedArray(array.id);
    openModal();
  };

  return (
    <div className="grid justify-content-center">
      <p className="text-5xl lg:text-4xl md:pb-7">Galerie</p>
      <div className="grid col-12 justify-content-center px-4">
        {arrays.map((array, index) => (
          <div className="col-12 md:col-4 py-8" key={index}>
            <div
              className="fondu relative cursor-pointer"
              value={array}
              onClick={() => {
                handleClick(array);
                openModal();
              }}
            >
              <img
                className="galerie-images-bas border-round-xl absolute left-0 w-full"
                src={`data:image/webp;base64,${array.image2compressed}`}
                alt={array.name}
              ></img>
              <img
                className="galerie-images-haut border-round-xl absolute left-0 w-full"
                src={`data:image/webp;base64,${array.imagecompressed}`}
                alt={array.name}
              ></img>
            </div>
            <div className="g-array-text w-full">
              <p className="m-0 text-4xl md:text-2xl lg:text-xl">{array.name}</p>
              <p className="text-lg">{array.dimension}</p>
              <hr></hr>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && selectedArray.id && (
        <GaleryModal isOpen={isModalOpen} onClose={closeModal}>
          <ArrayDetails array={selectedArray} mainPic={mainPic}></ArrayDetails>
        </GaleryModal>
      )}
    </div>
  );
}

export function ArrayDetails({ array, mainPic }) {
  // Opening fullscreen array main pic

  // Set main pic state
  const [mainPic1, setMainPic1] = useState(mainPic);
  useEffect(() => {
    setMainPic1(mainPic);
  }, [mainPic]);
  const handleMainPic = (e) => {
    const newMainPic = e.target.getAttribute("value");
    setMainPic1(newMainPic);
  };
  return (
    <div className="align-content-center justify-content-center flex flex-direction-column grid col-11 m-3 z-2">
      <p className="array-title w-full text-5xl ">{array.name}</p>
      <div className="card flex grid col-11 md:col-6 md:mr-3 align-content-center">
        <Image
          className="array-main-pic"
          width="100%"
          src={`data:image/webp;base64,${mainPic1}`}
          alt={array.name}
          preview
          
          /
        
        >
      </div>
      <div className="array-info col-11 md:col-5 text-2xl md:text-lg relative text-center">
          <div className="array-description">
            {array.year? (<><p>Année :</p> {array.year}</>) : (<p></p>)}
            <p>Description :</p> {array.description}
          </div>
          <br></br>
          <div className="array-dimension">
            <p>Dimensions :</p>
            {array.dimension}
          </div>
          <hr></hr>
          <div className="selection">
          <img
            src={`data:image/webp;base64,${array.image}`}
            value={array.image}
            onClick={(e) => handleMainPic(e)}
            alt={array.name}
          ></img>

          <img
            src={`data:image/webp;base64,${array.image2}`}
            value={array.image2}
            onClick={(e) => handleMainPic(e)}
            alt={array.name}
          ></img>

          <img
            src={`data:image/webp;base64,${array.image3}`}
            value={array.image3}
            onClick={(e) => handleMainPic(e)}
            alt={array.name}
          ></img>

          <img
            src={`data:image/webp;base64,${array.image4}`}
            value={array.image4}
            onClick={(e) => handleMainPic(e)}
            alt={array.name}
          ></img>
          </div>
      </div>
      
    </div>
  
  );
}

export default Galery;
