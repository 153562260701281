import React from "react";
import "../App.css";
import "./app.jsx";
import { GaleryHeader as ContactHeader } from "./Galery.jsx";
import { Footer } from "./app.jsx";

export function Contact() {
  return (
    <div className="contact-page text-center absolute h-30rem grid col-12 text-2xl md:text-lg">
      <div>
        <ContactHeader></ContactHeader>
        <Contactcontain></Contactcontain>
      <Footer></Footer>
      </div>
    </div>
  );
}

export function Contactcontain() {
  return (
    <div className="flex grid w-full flex-column text-center col-12">
      <p className="text-5xl font-light md:text-4xl pt-8">Contactez Jim Debergue </p>
      <p className="">
        Vous avez des question sur des oeuvres ou souhaitez me contacter pour
        une collaboration ?<br></br>
        <br></br>
        N'hesitez pas à m'envoyer un message !
      </p>
    </div>
  );
}
