import React from "react";

// This is modal for Array-details
const GaleryModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className= "fixed top-0 left-0 h-full flex align-items-center" style={{backgroundColor : "rgba(0, 0, 0, 0.887)"}}>
      <div className="modal bg-white border-round-3xl overflow-scroll p-3" style={{maxHeight : "80%"}}>
        <div className="modal-content">
          <button className="close-button fixed bg-transparent border-none text-8xl cursor-pointer z-5" onClick={onClose} style={{color: "red", right: "5%"}}>
            &times;
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default GaleryModal;


